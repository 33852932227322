import React from "react";
import PropTypes from "prop-types";
import foodinfo from "../../../content/meta/foodinfo";

import kebabCase from "lodash/kebabCase"
import config from "../../../content/meta/config";
import avatar from "../../images/jpg/avatar.jpg";

import { Link, graphql } from "gatsby"

const Ingredient = props => {
  const { ingredients, quantities,theme } = props;
  const numIngredients = ingredients.length;
  
  const ingredientItems = ingredients.map((ingredient,i) => {
    var url="ingredients/"+ingredient.replace(/-/g, '').toLowerCase()
    url = url.replace(/\s+/g, '-')
    if(foodinfo[ingredient.toLowerCase()]){
       return <li><i>{quantities[i]} </i> <b>{ingredient}</b>
            <a target="_blank" href={foodinfo[ingredient.toLowerCase()]}>
              | Buy |
            </a>
             <Link to={url} >More Recipes with {ingredient} </Link>
            </li>
    }
    else{
      return <li><i>{quantities[i]} </i><b>{ingredient}</b>  |  <Link to={url} >More Recipes with {ingredient}</Link></li>
    }
    
}
  );

  return (
    <React.Fragment>
      <ul className="note">
        {ingredientItems}
      </ul>

      {/* --- STYLES --- */}
      <style jsx>{`


        .note {
          font-size: 0.9em;
          line-height: 1.6;
          margin-bottom:5px;
        }

      `}</style>
    </React.Fragment>
  );
};

Ingredient.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default Ingredient;
