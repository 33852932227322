import React from "react";
import PropTypes from "prop-types";
import toolinfo from "../../../content/meta/toolinfo";

import kebabCase from "lodash/kebabCase"
import config from "../../../content/meta/config";
import avatar from "../../images/jpg/avatar.jpg";

import { Link, graphql } from "gatsby"

const Tool = props => {
  const { tools, theme } = props;
  const numTools = tools.length;
  const toolItems = tools.map((tool,i) => {
    var url="tools/"+tool.replace(/-/g, '').toLowerCase()
    url = url.replace(/\s+/g, '-')
       return <li><b>{tool}</b>
            <a target="_blank" href={toolinfo[tool.toLowerCase()]}>
              | shop |
            </a>
            <Link to={url} > More Recipes using {tool}s </Link>
            </li>
    
}
  );

  return (
    <React.Fragment>
      <ul className="note">
        {toolItems}
      </ul>

      {/* --- STYLES --- */}
      <style jsx>{`


        .note {
          font-size: 0.9em;
          line-height: 1.6;
          margin-bottom:5px;
        }

      `}</style>
    </React.Fragment>
  );
};

Tool.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default Tool;
