import React from "react";
import PropTypes from "prop-types";
import "prismjs/themes/prism-okaidia.css";

import asyncComponent from "../AsyncComponent";
import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";
import Meta from "./Meta";
import Diet from "./Diet"
import Tool from "./Tool"
import Ingredient from "./Ingredient"
import Author from "./Author";
import NextPrev from "./NextPrev";
import CommentsDisqus from "./CommentsDisqus";


const Share = asyncComponent(() =>
  import("./Share")
    .then(module => {
      return module.default;
    })
    .catch(error => {})
);

const Post = props => {
  const {
    post,
    post: {
      html,
      fields: { prefix, slug },
      frontmatter: { title, author, category, diet,path,tools,ingredients,quantities }
    },
    authornote,
    facebook,
    next: nextPost,
    prev: prevPost,
    theme
  } = props;

console.log(post)
var dietComponent=''
if (diet){
  dietComponent=<Diet diets={diet} theme={theme} />
}

var toolComponent=<b> NO TOOL </b>
if (tools){
  toolComponent=<Tool tools={tools} theme={theme} />
}
var ingredientComponent=<b> NO Ingredients </b>
if (ingredients){
  ingredientComponent=<Ingredient ingredients={ingredients} quantities={quantities} theme={theme} />
}
  return (
    <React.Fragment>
      <header>
        <Headline title={title} theme={theme} />
        <Meta prefix={prefix} author={author} category={category} theme={theme} diet={diet}/>
        {dietComponent}
        
      </header>
      <h1>
      Ingredients used
      </h1>
      {ingredientComponent}
      <Bodytext html={html} theme={theme} />
      <h1>
      Tools used
      </h1>
      {toolComponent}
      <footer>
        <Share post={post} theme={theme} />
        <Author note={authornote} theme={theme} />
        <NextPrev next={nextPost} prev={prevPost} theme={theme} />
        <CommentsDisqus slug={slug} title={title} theme={theme} />
      </footer>
    </React.Fragment>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  authornote: PropTypes.string.isRequired,
  facebook: PropTypes.object.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default Post;
