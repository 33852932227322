import React from "react";
import PropTypes from "prop-types";
import Disqus from 'disqus-react';
import config from "../../../content/meta/config";

const CommentsDisqus = props => {
  const { slug, title,theme } = props;
  const disqusShortname = 'technically-cooking';
  const disqusConfig = {
            url: config.siteUrl+slug,
            identifier: slug,
            title: title,
        };
  return (
    <React.Fragment>
      <div id="post-comments" className="comments">
      
        <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
      </div>

      {/* --- STYLES --- */}
     
    </React.Fragment>
  );
};

CommentsDisqus.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default CommentsDisqus;
