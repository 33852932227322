import React from "react";
import PropTypes from "prop-types";

import kebabCase from "lodash/kebabCase"
import config from "../../../content/meta/config";
import avatar from "../../images/jpg/avatar.jpg";

import { Link, graphql } from "gatsby"

const Diet = props => {
  const { diets, theme } = props;
  const numDiets = diets.length;
  const dietItems = diets.map((diet,i) => {
    if (numDiets === i+1 ) {
       return <Link to={`/diets/${kebabCase(diet)}/`}>
              <b>{diet}</b>
            </Link>
    } else {
     return  <Link to={`/diets/${kebabCase(diet)}/`}>
              <b>{diet},</b>
            </Link>
    }
}
  );

  return (
    <React.Fragment>
      <div className="note">
        {dietItems}
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`


        .note {
          font-size: 0.9em;
          line-height: 1.6;
          margin-bottom:5px;
        }

      `}</style>
    </React.Fragment>
  );
};

Diet.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default Diet;
